
  .pricing-container {
    margin: 0 auto;
    padding: 50px 0 0;
    max-width: 960px;
    width: 100%;
  }
  
.projectPage {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    min-height: 100vh;
}

.pricing-header{
    height: 25vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.pricing-header > h1 {
    font-size: 4rem;
    font-family: var(--primaryFont);
}

.projectPage-container {
    padding: 3rem 2rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    font-family: var(--primaryFont);

}

.projectPage-search {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.project-container {
    margin-top: 5rem;
    width: 100%;
}

.project-grid {
    display: flex;
    gap: 4rem; 
}
.d-flex {
  display: flex;
}
@media (min-width: 992px) and (max-width: 1380px) {

}

@media screen and (max-width: 992px) {
    .pricing-header{
        height: 25vh;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .project-grid {
        gap: 3rem;
    }
}

@media screen and (max-width: 702px) {
    .project-grid {
        gap: 1.5rem;
    }
}

@media screen and (max-width: 550px) {
    .pricing-header > h1 {
        font-size: 3rem;
    }
}
@media screen and (max-width: 370px) {
    .pricing-header{
        height: 12vh;
    }
    .pricing-header > h1 {
        font-size: 2.5rem;
    }
}


.toggle {
    margin-top: 2rem;
    color: hsl(234, 14%, 74%);
    display: flex;
    align-items: center;
  }
  .toggle-btn {
    margin: 0 1rem;
  }
  .checkbox {
    display: none;
  }
  
  .sub {

    display: flex;
    justify-content: flex-start;
    align-items: center;
    /* height: 25px;
    width: 50px; */
    height: 1.6rem;
    width: 3.3rem;
    border-radius: 1.6rem;
    padding: 0.3rem;
  }
  .circle {
    background-color: #fff;
    height: 1.4rem;
    width: 1.4rem;
    border-radius: 50%;
  }
  .checkbox:checked + .sub {
    justify-content: flex-end;
  }
  
  .wrapper {
    display: block;
    position: absolute;
    width: 100vw;
    height: 100vh;
    z-index: 2;
  }
  a, a:visited, a:focus {
    text-decoration: none;
    color: #FFFFFF;
  }
  .videoWrapper {
    display: block;
    float: left;
    margin-top: 30px;
  }
  
  #bg-animation {
    display: block;
    position: absolute;
    width: 100vw;
    height: 100vh;
    z-index: -1;
  }