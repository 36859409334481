$bg-color: #F6F8FC;
$primary-text-color: #ffffff;
$secondary-text-color: #9EA6AE;

.heading-accordion{
    color: $primary-text-color;
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 2rem;
  }
  
  .container-accordion {
    margin: 0 auto;
    padding: 2rem;
    text-align: center;
  }
  
  form {
    display: flex;
    width: 35rem;
    background-color: #fff;
    align-items: center;
    margin: 0 auto;
    border-radius: 1rem;
    
    svg {
      height: 1rem;
      fill: $secondary-text-color;
      position: fixed; 
      width: 4rem;
    }
  }
  
  
  .faq {
    margin: 1rem 0;
  }
  .question-wrapper {
    width: 50rem;
    margin: 0 auto;
    padding: 1rem;
    transition: 1s;
  }
  
  .question {
    display: flex;
    font-size: 1rem;
    font-weight: 500;
    color: $primary-text-color;  
    display: flex;
    justify-content: space-between;
    
    svg {
      width: 1rem;
      height: 1.5rem;
      fill: $secondary-text-color;
    }
    
    svg.active {
      transform: rotate(180deg);
    }
    
    svg:hover {
      opacity: 0.8;
    }
  }
  
  button {
    background-color: transparent;
    border: none;
    cursor: pointer;
  }
  
  button:focus {
    outline: none;
  }
  
  .answer {
    display: none;
  }
  
  .answer.active {
    display: block;
    text-align: left;
    padding-top: 1.5rem;
    font-weight: light;
    font-size: 1.2rem;
    line-height: 1.5;
    color: $secondary-text-color;
    height: 0%;
    animation: slidein 0.4s;
    animation-fill-mode: forwards;
  }
  
  @keyframes slidein {
    from {
      opacity: 0.3;
      transform: translateY(-20%)
    } to {
      opacity: 1;
      transform: translateY(0%);
    }
  }

//   question wrapper with mobile and ipad
    @media screen and (max-width: 768px) {
        .question-wrapper {
        width: 100%;
        }
    }
    
    @media screen and (max-width: 425px) {
        .question-wrapper {
        width: 100%;
        }
    }
    
    @media screen and (max-width: 375px) {
        .question-wrapper {
        width: 100%;
        }
    }
    
    @media screen and (max-width: 320px) {
        .question-wrapper {
        width: 100%;
        }
    }
  
  