/* Cards */
.card-column {
    width: 50%;
    float: left;
    padding: 4%;
    box-sizing: border-box;
  }
  
  .column-1 {
    padding-top: 100px;
  }
  
  .card {
    width: 92%;
    max-width: 340px;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    background: #EB5160;
    color: #fff;
    cursor: pointer;
    margin-bottom: 60px;
  }
  
  .border {
    position: absolute;
    width: 100%;
    height: 100%;
    padding: 6px;
    border: 1px solid #fff;
    opacity: 0.5;
    left: -6px;
    top: -6px;
  }
  
  .card h1 {
    position: relative;
    padding: 190px 0px 100px 10px;
    width: 90%;
  }
  
  .card > img {
    width: 90%;
    position: absolute;
    top: -6%;
    left: -6%;
  }
  
  .card-color-0 {
    background-color: #EB5160;
  }
  
  .card-color-1 {
    background-color: #8F3985;
  }
  
  .card-color-2 {
    background-color: #8DAA91;
  }
  
  .card-color-3 {
    background-color: #888DA7;
  }
  
  /* The cover (expanding background) */
  .cover {
    position: fixed;
    background: #EB5160;
    z-index: 100;
    transform-origin: 50% 50%;
  }
  
  /* The open page content */
  .open-content {
    width: 100%;
    z-index: 110;
    position: absolute;
    opacity: 0;
    pointer-events: none;
  }
  
  .open-content img {
    position: relative;
    width: 90%;
    margin-left: 3%;
    margin-top: 20px;
    z-index: 5;
  }
  
  .open-content .text {
    background: #fff;
    margin-top: -56%;
    padding: 60% 5% 5% 5%;
    width: 80%;
    margin-left: 5%;
    margin-bottom: 5%;
  }
  
  .open-content .text h1, .open-content .text p {
    max-width: 700px;
    margin-left: auto;
    margin-right: auto;
  }
  
  .close-content {
    display: block;
    position: absolute;
    right: 12px;
    top: 12px;
    width: 30px;
    height: 30px;
  }
  
  .close-content span {
    background: #222;
    width: 30px;
    height: 6px;
    display: block;
    position: absolute;
    top: 14px;
  }
  
  .x-1 {
    transform: rotate(45deg);
  }
  
  .x-2 {
    transform: rotate(-45deg);
  }
  
  /* 
  ** Transitions
  */
  
  .card {
    transition: opacity 200ms linear 320ms, transform 200ms ease-out 320ms;
  }
  
  .border {
    transition: opacity 200ms linear, transform 200ms ease-out;
  }
  
  .card img {
    transition: opacity 200ms linear 0ms, transform 200ms ease-in 0ms;
  }
  
  .card h1 {
    transform: translate3d(20%, 0px, 0px);  
    transition: opacity 200ms linear 120ms, transform 200ms ease-in 120ms;
  }
  
  /* Clicked card */
  .card.clicked img {
    transform: translate3d(0px, -40px, 0px);
    opacity: 0;
  }
  
  .card.clicked .border {
    opacity: 0;
    transform: scale(1.3);
  }
  
  .card.out, .card.out img {
    transform: translate3d(0px, -40px, 0px);
    opacity: 0;
  }
  
  .card.out h1, .card.clicked h1 {
    transform: translate3d(20%, -40px, 0px);
    opacity: 0;
  }
  
  .cover {
    transition: transform 300ms ease-in-out;
  }
  
  .open-content {
    transition: opacity 200ms linear 0ms;
  }
  
  .open-content.open {
    opacity: 1;
    pointer-events: all;
    transition-delay: 1000ms;
  }
  
  /* 
  ** Media Queries
  */
  
  @media screen and (max-width: 600px) {
    .card-column {
      width: 90%;
    }
    
    .column-1 {
      padding-top: 0px;
    }
    
    .open-content img {
      margin-top: 40px;
    }
  }